;(function () {
    'use strict'

    function onDocumentReady() {
        const teasersWithStage = document.querySelectorAll('.cmp-teasers-stage')

        if (teasersWithStage) {
            teasersWithStage.forEach(teaserWithStage => {
                const navigation = teaserWithStage.querySelector('.cmp-teasers-stage__navigation')
                const previousButton = navigation?.querySelector('.cmp-teasers-stage__navigation-button--previous')
                const nextButton = navigation?.querySelector('.cmp-teasers-stage__navigation-button--next')
                const activeTeaser = navigation?.querySelector('.cmp-teasers-stage__navigation-teaser-counter-current')
                const totalTeasers = navigation?.querySelector('.cmp-teasers-stage__navigation-teaser-counter-total')
                const teasersContainer = teaserWithStage.querySelector('.cmp-teasers-stage__teasers')
                const teasers = [...teaserWithStage.querySelectorAll('.cmp-teasers-stage__teaser')]

                if (!navigation || !activeTeaser || !totalTeasers || !teasersContainer || teasers.length === 0) {return}

                let currentIndex = 0
                const total = teasers.length
                const gap = 24
                const teaserWidth = teasers[0].getBoundingClientRect().width

                activeTeaser.textContent = currentIndex + 1
                totalTeasers.textContent = total

                function updateNavigationVisibility() {
                    const isOverflowing = teaserWithStage.scrollWidth > teaserWithStage.clientWidth
                    navigation.classList.toggle('cmp-teasers-stage__navigation--visible', isOverflowing)
                }
                function updateTeaserPosition() {
                    const offset = currentIndex * (teaserWidth + gap)
                    teasersContainer.style.marginLeft = `-${offset}px`
                    activeTeaser.textContent = currentIndex + 1
                    teasers.forEach(teaser => teaser.style.width = `${teaserWidth}px`)
                }

                nextButton?.addEventListener('click', () => {
                    if (currentIndex < total - 1) {
                        currentIndex++
                        updateTeaserPosition()
                    }
                })

                previousButton?.addEventListener('click', () => {
                    if (currentIndex > 0) {
                        currentIndex--
                        updateTeaserPosition()
                    }
                })

                updateTeaserPosition()
                updateNavigationVisibility()

                window.addEventListener('resize', () => updateNavigationVisibility())
            })
        }
    }

    if (document.readyState !== 'loading') {
        onDocumentReady()
    } else {
        document.addEventListener('DOMContentLoaded', onDocumentReady)
    }
})()
