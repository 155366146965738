import { isSafari } from '@site/js/utils/utils'

import { TsComponentRegistry } from '../ts-component-registry'

const videoWrapperClass = '.cmp-video-wrapper'

class HorizontalTeaserComponent extends TsComponentRegistry.Component<HTMLDivElement> {
  constructor(element: HTMLDivElement) {
    super(element)
    this.init()
  }

  private init(): void {
    this.videoHandler()
  }

  private videoHandler(): void {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const videoWrapper: HTMLDivElement = entry.target.querySelector(videoWrapperClass)
          const video: HTMLVideoElement = videoWrapper?.querySelector('video')

          if (video) {
            this.setVideoAttributes({ videoWrapper, video })
            if (entry.isIntersecting) {
              video.play()
            } else {
              video.pause()
            }
          }
        })
      },
      {
        threshold: [0.2],
      },
    )

    if (this.element.querySelector(`${videoWrapperClass} video`)) {
      observer.observe(this.element)
    }
  }

  private setVideoAttributes(videoObject = { videoWrapper: null, video: null }): void {
    const { videoWrapper, video } = videoObject
    const { videourl: src, posterimageurl: poster } = videoWrapper.dataset
    if (!video.src) {
      video.src = src
    }
    if (!video.poster) {
      video.poster = poster
    }
    if (isSafari) {
      video.style.backgroundImage = `url('${poster}')`
    }
    if (video.autoplay != null) {
      video.autoplay = null
    }
  }
}

TsComponentRegistry.registerComponent('.cmp-horizontalteaser-v2', HorizontalTeaserComponent)
