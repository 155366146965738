import './Infobox.scss'

import Icon from '@react/Atoms/Icon'
import { cls } from '@react/utils/classname'
import { useEffect, useRef, useState } from 'react'

export type InfoboxProps = {
  formValidationInfobox?: string
  withIcon?: string
  focusClass?: string
  successTitle?: string
  successDescription?: string
  errorTitle?: string
  errorDescription?: string
  linkId?: string
  title?: string
  content?: string
}

export default function Infobox({
  formValidationInfobox,
  withIcon,
  focusClass,
  successTitle,
  successDescription,
  errorTitle,
  errorDescription,
  linkId,
  title,
  content,
}: InfoboxProps) {
  const [isFormValid, setIsFormValid] = useState(null)
  const [invalidList, setInvalidList] = useState([])
  const [infoboxFocusClass, setInfoboxFocusClass] = useState(focusClass)
  const [dataSuccessTitle, setDataSuccessTitle] = useState('')
  const [dataSuccessDescription, setDataSuccessDescription] = useState('')
  const [dataErrorTitle, setDataErrorTitle] = useState('')
  const [dataErrorDescription, setDataErrorDescription] = useState('')
  const [linkTemplate, setLinkTemplate] = useState(null)

  useEffect(() => {
    setInfoboxFocusClass(focusClass)
    setDataSuccessTitle(successTitle)
    setDataSuccessDescription(successDescription)
    setDataErrorTitle(errorTitle)
    setDataErrorDescription(errorDescription)
  }, [focusClass, successTitle, successDescription, errorTitle, errorDescription])

  useEffect(() => {
    if (linkId != null) {
      const linkElement = document.getElementById(linkId)
      if (linkElement) {
        linkElement.querySelector('div')?.classList.add('cmp-button--primary-positive')
        const linkElementHtml = linkElement.innerHTML
        linkElement.remove()
        setLinkTemplate(linkElementHtml)
      }
    }
    if (formValidationInfobox === 'true') {
      const observer = new MutationObserver(() => {
        const refDataset = containerRef?.current.dataset
        setIsFormValid(refDataset?.isFormValid === 'true')
        setInvalidList(JSON.parse(refDataset?.invalidList || '[]'))
      })
      observer.observe(containerRef?.current, {
        attributes: true,
      })

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  const iconType = isFormValid ? 'circle-check' : 'warning'
  const containerRef = useRef(null)

  return (
    <>
      {formValidationInfobox === 'true' ? (
        <div
          tabIndex="0"
          className={cls({
            'cmp-infobox-container': true,
            'cmp-infobox-container--success': isFormValid,
          })}
        >
          <div ref={containerRef} className={`${infoboxFocusClass} infobox-wrapper`} tabIndex={0}>
            {isFormValid != null && (
              <div
                className={cls({
                  'cmp-infobox__box': true,
                  'cmp-infobox--success': isFormValid,
                  'cmp-infobox--warning': !isFormValid,
                })}
              >
                <div id="infobox-icon">
                  <Icon iconName={iconType} />
                </div>
                <h4 className="cmp-infobox__title">{isFormValid ? dataSuccessTitle : dataErrorTitle}</h4>
                <p className="cmp-infobox__message">{isFormValid ? dataSuccessDescription : dataErrorDescription}</p>
                {!isFormValid && (
                  <ul className="cmp-infobox__invalid-list">
                    {invalidList.map(item => (
                      <li key={item.message}>
                        {item.href ? (
                          <a className="cmp-infobox__invalid-list-link" href={item.href}>
                            {item.message}
                          </a>
                        ) : (
                          item.message
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="cmp-infobox__container" tabIndex="0">
          {withIcon === 'true' && <span className="cmp-infobox__icon icon-info"></span>}
          {title && <h4 className="cmp-infobox__title">{title}</h4>}
          {content && <div className="cmp-infobox__content cmp-text" dangerouslySetInnerHTML={{ __html: content }}></div>}
          {linkId != null && <div className="cmp-infobox__link" dangerouslySetInnerHTML={{ __html: linkTemplate }}></div>}
        </div>
      )}
    </>
  )
}
