/**
 * KEPT FOR BACKWARDS COMPATIBILITY
 * DO NOT EDIT THIS FILE
 * USE {@link ../main.ts}
 */

/**
 * Styles
 */
import 'regenerator-runtime/runtime'
import './index.scss'
/**
 * AEM components
 */
import '@components/map-app/map-app.js'
import '@components/button/button.js'
import '@components/slider/slider.js'
import '@components/contact/contact.js'
import '@components/text/text.js'
import '@components/download/download.js'
import '@components/image/responsiveimage/parallax/zoom.js'
import '@components/image/responsiveimage/parallax/vertical-depth.js'
import '@components/videocontainer/parallax-effects/vertical-scrolling.js'
import '@components/videocontainer/parallax-effects/image-mask.js'
import '@components/pagestage/pagestage-textonly.js'
import '@components/form-container/form-container.js'
import '@components/media-gallery/media-gallery.js'
import '@components/stockticker/stockticker'
import '@components/carousel/carousel'
import '@components/interactiontoolbar/interactiontoolbar.js'
import '@components/tabs/tabs.js'
import '@components/abblinkspopup/abblinkspopup'
import '@components/parallax-scroll/parallax-scroll'
import '@components/page-tracker/page-tracker'
import '@components/slideupteasers/slideupteasers'
import '@components/pagestagev2/pagestagev2'
import '@components/teaserHeightAdjustment/teaserHeightAdjustment'
import '@components/footer/footer'
import '@components/teasers-with-stage/teasers-with-stage'

import { BIG_NUMBERS_SELECTOR, BigNumber, BigNumbersObserver } from '@components/bignumbers/bignumbers.ts'
import { CountrySelector } from '@components/countryselector/countryselector.js'
import { CustomSelect } from '@components/custom-select'
import { Footnotes } from '@components/footnotes/footnotes'
import { Header } from '@components/header/header'
import { Profile } from '@components/header/profile'
import { ScrollIndicator } from '@components/homepage-stage/homepage-stage'
import { Languagenavigation } from '@components/languagenavigation/languagenavigation'
import { Lightbox } from '@components/lightbox/lightbox'
import { ModalContainerPlugin } from '@components/modal-container/modal-container-plugin'
import { Navigation } from '@components/navigation/navigation'
import { TeaserDropDown } from '@components/on-page-navigation/navigation-teaser'
import { AgendaWatermarkTitle } from '@components/specialevent/specialevent.js'
import { StickyHeaderSearch } from '@components/stickyheadersearch/stickyheadersearch.js'
import { TeaserContainer } from '@components/teasercontainer/teasercontainer'
import { VideoEmbed } from '@components/videoembed/videoembed'

import handleFilterbarInitialLoadingSpinner from './js/utils/filterbarInitialLoadingSpinner'
import { initRequest } from './js/utils/request'

ModalContainerPlugin().init()
CustomSelect().init()
Footnotes().init()
AgendaWatermarkTitle().init()

Array.from(document.querySelectorAll('.cmp-teasercontainer'))
  .filter(teaserContainer => teaserContainer.querySelector('div'))
  .forEach(teaserContainer => TeaserContainer(teaserContainer).init())

Header().init()
Navigation().init()
Profile().init()
Languagenavigation().init()
ScrollIndicator().init()
StickyHeaderSearch().init()
CountrySelector().init()
TeaserDropDown().init()

Array.from(document.querySelectorAll('.cmp-videoembed')).forEach(embed => VideoEmbed(embed).init())

Array.from(document.querySelectorAll('a.link_external')).forEach(link => {
  link.target = '_blank'
  link.rel = 'noreferrer noopener'
})

const bigNumbers = Array.from(document.querySelectorAll(BIG_NUMBERS_SELECTOR)).map(element => new BigNumber(element).getElement())
BigNumbersObserver(bigNumbers)

Lightbox().init()

initRequest()
handleFilterbarInitialLoadingSpinner()
